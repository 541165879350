// Firstly set your globales values
@import url('https://fonts.googleapis.com/css2?family=Signika:ital,wght@0,300;0,900;1,300;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap');

$primary : #1aa880;
$secondary : #199d78;
$light-background : rgba(188, 232, 202, 0.1);
$text-color-primary : #4d4d4d;
$text-color-secondary : #999999;
$white : #FFF;
$padding-block :15px;
$padding-button:0px $padding-block;
$breakpoint-mobile-small: 370px;
$breakpoint-mobile: 820px;
$breakpoint-tablet: 1069px;
$breakpoint-large: 1480px;
$breakpoint-iframe1: 810px;
$breakpoint-iframe2: 1580px;
$page-width: 960px;

// ------------------ // 
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Signika', sans-serif;
}

body {
  background-color: #f9f9f9;
}

h1 {
  all: unset;
}

h2 {
  color : $primary;
}

h4 {
  font-size:1.5em;
  text-transform: uppercase;
  color:$text-color-secondary;
  padding-bottom:15px;
} 

h5 { 
  text-align: center;
  font-size:1.2em;
  text-transform: uppercase;
  color:$text-color-secondary;
  padding-bottom:15px;
}

body {
  display:block;
  position: relative;
  color: $text-color-secondary;
}

.radius {
  border-radius: 20px;
}

.header {
  width: 100%;
  min-height: 98px;
  display: flex;
  -webkit-box-shadow: 0px 1px 15px -4px rgba(0,0,0,0.77); 
  box-shadow: 0px 1px 15px -4px rgba(0,0,0,0.77);
  background-color: white;
  text-align: right;

  & .bordered {
    display : block;
    width:280px;
    border : 1px $text-color-primary solid;
    padding: 0px;
    @media (max-width:$breakpoint-mobile) {
      width:100%;
    }
  }

  & .price-offer {
    font-size : 3rem;
    font-weight: bold;
  }

  & .txt-little {
    font-size : 0.8rem;
    font-weight: thin;
  }

  & .argument-accroche {
    font-size: 18px;
    line-height: 24px;
    color: #999999;
    font-weight: 500;
  }

  & .notation {
    display: flex;
    width: 174px;
    align-items: center;
    padding: 18px;
  }

  & .header-content {
    margin: auto;
    max-width: $page-width;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    text-align: right;
  }

  @media (max-width: $breakpoint-mobile) {
    .header-content {
      text-align: center;
      flex-direction: column-reverse;
      text-align: center;
      align-items: center;
    }
  }
}

.logo {
  width: 214px;
}

.nav-left {
  text-align:center;
  display:flex;
  justify-content: center;
}

.nav-center {
  display: flex;
  align-items: center;
}

.nav-right {
  text-align:center;
  display: flex;
  flex-wrap: wrap;
  vertical-align: center;
  align-content: center;
  padding:18px;
}

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $page-width;
  margin: 0 auto;
}

#main-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 64px;
  row-gap: 48px;
}

@media (max-width: $breakpoint-mobile) { 
  #main-content {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
  }
}

.topping_of_page {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  row-gap: 16px;
  font-size: 16px;
  line-height: 20px;
  color: $text-color-primary;
  align-items: center;
}

@media (max-width: $breakpoint-mobile) { 
  .topping_of_page {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    padding: 24px 16px 0 16px;
    height: 100%;
  }
}

#reviews {
  width: 100%;
  background-color: white;
  padding: 16px 0 32px 0;
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  row-gap: 32px;
}

@media (max-width: $breakpoint-mobile) { 
  #reviews {
    padding: 16px 16px 32px 16px;
    margin-top: 10px;
  }
}

#review-content {
  width: 100%;
  max-width: $page-width;
}

#review-text {
  width: 100%;
  max-width: $page-width;
}

#reviews-container {
  max-width: $page-width;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  row-gap: 16px;
  flex-direction: column;
  text-align: center;
}

#review-box-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  column-gap: 32px;
  flex-direction: row;
}

@media (max-width: $breakpoint-mobile) { 
  #review-box-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    column-gap: 32px;
    flex-direction: column;
    gap: 32px;
  }
}


.reviews-box {
  max-width: 45%;
  margin: 0 auto;

  & .pseudo {
    margin-top: 28px;
    font-size: 13px;
    color: $text-color-primary;
    display: block;
  }

  & .review-origin {
    font-size: 10px;
    color: $text-color-secondary;
  }
}

.title-review-container {
  font-size: 37px;
  line-height: 45px;
  color: $primary;
  font-weight: bold;
}

.review-main-text {
  font-size: 18px;
  line-height: 21px;
  color: $text-color-primary;
}

.review-user-comment {
  margin-top: 16px;
  font-size: 13px;
  line-height: 15px;
  color: $text-color-secondary;
}

.icon-sub-banner {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon {
  width: 28px;
}

.arguments {
  max-width: 100%;
  width: 407px;
}

@media (max-width: $breakpoint-mobile) { 
  .arguments {
    margin: 0 auto;
  }
}

.illustration-banniere {
  width: 407px;
}

@media (max-width: $breakpoint-mobile) { 
  .illustration-banniere {
    width: 100%;
  }
}

.full-height {
  height: 100%;
  width: 100%;
}

.logos-partenaires {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 8px;
}

.form-holder {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

@media (min-width: $breakpoint-mobile) { 
  iframe, .iframe  {
    width: 100%;
    height: 100%;
    border: 0;
    background-color: white;
    border-radius: 35px 0 0 0;
    margin-top: 28px;
    min-height: 820px;
  }
}

@media (max-width: $breakpoint-mobile) { 
  iframe, .iframe  {
    height: 810px;
    width: 100%;
    border: 0;
    background-color: white;
    border-radius: 35px 0 0 0;
    margin-top: 28px; 
  }
}

@media (max-width: $breakpoint-mobile-small ) { 
  iframe, .iframe  {
    height: 820px;
    width: 100%;
    border: 0; 
    background-color: white;
    border-radius: 35px 0 0 0;
    margin-top: 28px;
  }
}

.accroche-form-text {
  display: flex;
  flex-direction: column;
}

@media (max-width: $breakpoint-mobile) { 
  .accroche-form-text {
    text-align: center;
  }
}

.form-title {
  color: $primary;
  font-size: 36px;
  line-height: 44px;
}

.subtitle {
  color: $text-color-primary;
  font-size: 21px;
  line-height: 26px;
  font-weight: bold;
  margin-top: 16px;
}

.form-text-content {
  color: $text-color-primary;
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  margin-top: 8px;
}

.ask-quotation{
  width: 100%;
  display: flex;
}

.ask-quotation-button {
  margin: auto;
  width: fit-content;
  padding:12px;
  font-size: 22px;
  border: none;
  border-radius: 20px;
  color: white;
  background-color: #1aa880;
  cursor: pointer;

  &:hover {
    background-color: #11926d;
  }
}

.mentions-container {
  background-color: $light-background;
  padding:$padding-block;
  text-align: center;

  & p {
    padding-block: $padding-block;
  }

}

footer {
  width:100%;
  background: linear-gradient($primary,$secondary);
  color:$white;
  text-align: center;
  padding:$padding-block;
  cursor:pointer;
}

// For modal management 
.drawer {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal {
  position: absolute;
  top:25%;
  left:25%;
  background-color: $white;
  width:50%;
  padding:$padding-block;
  transition: ease all 1s;

  @media (max-width:$breakpoint-tablet) {
    top:10%;
    left:10%;
    width: 80%;
  }
}

.footer-link {
  color:white;
  text-decoration: none;
  cursor:pointer;
}

.hide-mobile {
  @media (min-width:$breakpoint-mobile) {
    display: initial;
  }

  @media (max-width:$breakpoint-mobile) {
    display: none;
  }
}

// For RGPD management 
.drawer-rgpd {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-rgpd {
  position: fixed;
  font-size: 14px;
  text-align: center;
  bottom:25%;
  left:25%;
  background-color: $white;
  width:50%;
  transition: ease all 1s;
  border:1px solid #CCC;
  padding: $padding-block;
  border-radius:20px 20px 20px 20px;

  
  @media (max-width:$breakpoint-tablet) {
    bottom:5%;
    left:10%;
    width: 80%;
    top: 5%;
    overflow-y: auto;
  }
}

#img-cookie {
  width:120px;
  position:absolute;
  bottom:20px;
  left:30px;
  z-index:-1000;

  @media (max-width:$breakpoint-tablet) {
    position:relative;
    padding:10px;
    margin-top:10px;
    width:40%;
    bottom:0;
    left:0;
  }
}
.rgpd-button {
  margin-top:15px;
}

.rgpd-input {
  width:100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  
  & span {
    width:30%;
    display:inline-block;
    font-size: 1.2em;
  }
}

@media (max-width:$breakpoint-tablet) {
  .rgpd-input {
    width:100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    
    & span {
      width:100%;
      display:inline-block;
      font-size: 1.2em;
    }
  }
}

.accept-all-rgpd {
  font-size: 1.3em;
  font-weight:bold;
  padding: $padding-button;
  border:none;
  color:$white;
  background-color:green;
  border-radius:5px;
  width:45%;
  height:30px;
  cursor:pointer;
}

@media (max-width:$breakpoint-tablet) {
  .accept-all-rgpd {
    height: auto;
  }
}

@media (max-width:$breakpoint-tablet) {
  .modal-title {
    margin-top: 12px;
  }
}

.without-accept-text {
  color:#AAA;
  float: right;
  font-size:0.8em;
  cursor:pointer;
}

.without-accept-text-sub {
  color:#AAA;
  margin-top:10px;
  font-size:0.8em;
  cursor:pointer;
}

.main-deny-button {
  font-size: 1.3em;
  padding: $padding-button;
  border:none;
  color:$white;
  background-color:rgb(194, 194, 194);
  border-radius:5px;
  width:45%;
  height:30px;
}

.cross-rgpd {
  float:right;
  font-size:19px;
  border:1px #CCC solid;
  height:30px;
  width:30px;
  border-radius: 15px;
  text-align: center;

  & :hover {
    cursor: pointer;
  }
}

.rgpd-sub-links {
  font-size: 0.8em;
  text-align: center;
  
}

.sub-link, .rgpd-details-link, .rgpd-details-close  {
  text-decoration: none;
  color:#888;
  cursor: pointer;
}

.sub-link:hover, .rgpd-details-link:hover, .rgpd-details-close:hover {
  text-decoration: none;
  color:#444;
}

.rgpd-button {
  margin-top:15px;
}

// RGPD Details modal : 
.drawer-rgpd-details {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-rgpd-details {
  position: fixed;
  font-size: 14px;
  text-align: center;
  bottom:25%;
  left:25%;
  background-color: $white;
  width:50%;
  transition: ease all 1s;
  border:1px solid #CCC;
  padding: $padding-block;
  border-radius:20px 20px 20px 20px;
  max-height:60%;
  overflow-y: auto;

  
  @media (max-width:$breakpoint-tablet) {
    bottom:25%;
    left:10%;
    width: 80%;
  }
}

.cookie-details-content {
  text-align: center;
  background-color: rgba(245,245,245,1);
  width:100%;
  margin-top:15px;
  padding:10px;

  & p {
    padding:10px;
  }
}

.cookie-type-title {
  Font-size:1.1em;
  padding:10px;
  font-weight: bold;
}

.cookie-details-dropdown-title {
  margin-top:10px;
  text-decoration: underline;
  cursor:pointer;
  opacity: 0.8;
}
.cookie-details-dropdown-title:hover {
  opacity: 1;
}

.cookie-valid-partial {
  display:block;
  margin:auto;
  margin-top:5px;
  padding:5px;
  cursor:pointer;
  color:#888;
  background-color:rgba(230, 230, 230, 0.4);
  border-radius:5px;
  width:45%;
}

.cookie-valid-partial:hover {
  color:#444;
}

// Table cookie details management
.essentials-table-details,
.statistiques-table-details,
.marketing-table-details {
  margin-top: $padding-block;
  width: 100%;
  background-color: $white;
  text-align: left;
  font-size:0.9em;

  & td {
    padding:5px;
  }
  & tr {
    border-bottom:1px solid #AAA;
    border-collapse: separate;
    border-spacing: 0;
  }
}

.table-head {
  font-weight: bold;
  width:30%;
}

.legals {
  display: none;
}

.contacts {
  display:none;
}

.open {
  display: block;
  transition: ease all 2s;
}

.hidden {
  display:none;
  transition: ease all 2s;
}

.cross {
  float:right;
  border:1px #CCC solid;
  height:30px;
  width:30px;
  border-radius: 15px;
  text-align: center;

  & :hover {
    cursor: pointer;
  }
}

.fix {
  
  @media (min-width:$breakpoint-tablet) {
    position: fixed;
    top:0;
    width:50%;
    max-width: inherit;
    z-index: -1;
  }
 
}

.unfix {
  @media (min-width:$breakpoint-tablet) {
    position: relative;
  }
  
}

.no-scroll {
  overflow: hidden;
}

/* Specific for this theme */
#MQBLcontent {
  .mainContent {
    margin:0;
  }
}
